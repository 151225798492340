import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  OutboundLink,
  Image,
  BasicCarousel,
  componentIterator,
  Review,
  LocationsSorted,
} from "@bluefin/components";
import { Dropdown, Grid, p, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles, getPageHeroImage } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"home-page"}>
          <div className={"hero-images-container"}>
            <HeroElement
              header={fishermanBusiness.name}
              tagline={
                <div>
                  <p>Home of The Grandma Pizza!!</p>
                  <div>
                    <Dropdown
                      button={true}
                      text={"Order Online"}
                      className={"order-online-dropdown-cta"}
                    >
                      <Dropdown.Menu primary={true} size={"large"}>
                        <Dropdown.Item>
                          <OutboundLink
                            to={
                              "https://www.toasttab.com/antonios-house-of-pizza-kirkman-4626-s-kirkman-rd"
                            }
                            label={"Orlando"}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <OutboundLink
                            to={
                              "https://www.toasttab.com/antonios-house-of-pizza-kissimmee-2362-east-irlo-bronson-memorial-highway-ste-a103"
                            }
                            label={"Kissimmee"}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <OutboundLink
                            to={
                              "https://www.toasttab.com/antonios-house-of-pizza-apopka-1097-w-orange-blossom-trail"
                            }
                            label={"Apopka"}
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              }
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={550}
              gutter={false}
            />
          </div>
          <Grid
            stackable={true}
            verticalAlign={"middle"}
            centered={true}
            className={"image-text-row-container"}
          >
            <Grid.Column width={12}>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                centered={true}
                column={"equal"}
              >
                <Grid.Column width={9} textAlign={"center"}>
                  <Image
                    centered={false}
                    src={
                      "https://fisherman.gumlet.io/public/c41908ef-ba45-415d-ab3b-874711a1d8a3/grandma_classica-min_copy.jpg"
                    }
                  />
                </Grid.Column>
                <Grid.Column width={6} textAlign={"right"}>
                  <h2>ABOUT US</h2>
                  <p>
                    We believe that the foundation of a great pizza lies within
                    its dough. We make our dough and sauce in-house fresh every
                    day! Using imported Italian flour, which then undergoes over
                    36+ hours of fermentation time to achieve the perfect taste
                    and texture. Using organic tomato sauce, high quality
                    cheeses and fine olive oil, each pizza is then made from
                    scratch using our authentic recipes. Our homemade artisan
                    Grandma Pizzas are crafted in limited quantities, using only
                    traditional methods that make them truly unique. Due to our
                    labor-intensive process, once they're gone for the day,
                    they're gone! So come on down early and experience the
                    authentic taste and tradition of Antonio's.
                  </p>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            verticalAlign={"middle"}
            centered={true}
            className={"image-text-row-container"}
          >
            <Grid.Column width={12}>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                centered={true}
                column={"equal"}
                reversed={"mobile"}
              >
                <Grid.Column width={6} textAlign={"left"}>
                  <h2>THE FOUNDER</h2>
                  <p>
                    Growing up in the pizza business in Brooklyn NY, Antonio
                    developed a passion for pizza that not only nourishes the
                    body, but also feeds the soul. Out of the many pizza styles
                    Antonio knows, the Grandma Style Pizza has a special place
                    in his heart because it takes him back to the way he enjoyed
                    pizza as a child. A humble pie made with simple but high
                    quality ingredients. Antonio opened his very first business
                    at the age of 17 and always strives to keep the family
                    traditions alive. Today, you can experience Antonio's House
                    of Pizza with three locations across Florida: Kissimmee,
                    Orlando and now Apopka! When you step inside, you'll
                    immediately feel at home, with a comforting atmosphere and
                    friendly service that will make you want to stay awhile. And
                    of course, the pizza itself is nothing short of amazing,
                    with quality recipes that have been perfected over 29 years
                    of experience using only the freshest ingredients!
                  </p>
                </Grid.Column>
                <Grid.Column width={9} textAlign={"center"}>
                  <Image
                    size={"large"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/c41908ef-ba45-415d-ab3b-874711a1d8a3/Fresh_Ingredients.jpg"
                    }
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <div className={"reviews-container"}>
            <Header as={"h1"} textAlign={"center"}>
              Reviews
            </Header>
            <BasicCarousel
              size={3}
              pageIndicatorIcon={null}
              mobile={{ arrowsPosition: "top" }}
              items={componentIterator({
                iterator: fishermanBusiness.reviews,
                component: <Review as={"horizontalItem"} charLimit={200} />,
                propMap: { author: "author", text: "text" },
              })}
            />
          </div>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"component-section-container"}
          >
            <Grid.Column width={12}>
              <LocationsSorted
                locations={fishermanBusiness.locations.map((location) => {
                  const locationNameToOrderLink = {
                    Orlando:
                      "https://order.toasttab.com/online/antonios-house-of-pizza-kirkman-4626-s-kirkman-rd",
                    Kissimmee:
                      "https://order.toasttab.com/online/antonios-house-of-pizza-kissimmee-2362-east-irlo-bronson-memorial-highway-ste-a103",
                    Apopka:
                      "https://order.toasttab.com/online/antonios-house-of-pizza-apopka-1097-w-orange-blossom-trail",
                  };
                  return {
                    ...location,
                    actions: [
                      {
                        url: locationNameToOrderLink[location.name],
                        label: "Order Now",
                      },
                    ],
                  };
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        author
        text
        link
      }
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
    }
    fishermanBusinessWebsitePage(
      pageType: { eq: "Home" }
      title: { eq: "Home" }
    ) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
